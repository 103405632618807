import React from 'react';
import logo from './logo.svg';
import './App.scss';

function App() {
  return (
    <div className="App">
      <div className="container">
        <div className="title">Paper Waste Toy AMARIKKO</div>
        <div className="description">
          Children see things freely.
          Things that are meaningless to an adult will nevertheless be valuable and fun to a child.
          Printing company SHOEI was inspired by the rich imagination of children.
          From a different angle, even paper wastes that are going to be thrown away will start to look like interesting shapes.
          A dataset of over 3,000,000 doodles from Google was used to teach the AI ​​how to determine which of the 23 types of animals the paper wastes most resembled.
          Patterns were added to better resemble the animal but were kept to a minimum.
          We built a system that automatically generates the final printing data so that AMARIKKO can be made out of any piece of waste paper.
          This toy has a potential to reduce up to <span className="bold">4,659,000</span> tons of paper wastes per year in Japan.
          It also serves as a prime opportunity for the future generation to learn about the importance and profundity of paper.          
        </div>
        <div className="video">
          <iframe src="https://drive.google.com/file/d/1D-9H9EhxLGsQPbtYWvEPDRldISusuMrc/preview" width="640" height="480" allowFullScreen></iframe>          
        </div>

        <div className="photos">
          <div className="row">
            <div className="photo col s12">
              <img src="images/REFERENCE_2.jpg" alt="Amarikko reference image"/>
            </div>

            <div className="photo col s12 m6">
              <img src="images/REFERENCE_3.jpg" alt="Amarikko reference image"/>
            </div>

            <div className="photo col s12 m6">
              <img src="images/REFERENCE_4.jpg" alt="Amarikko reference image"/>
            </div>

            <div className="photo col s12 m6">
              <img src="images/REFERENCE_5.jpg" alt="Amarikko reference image"/>
            </div>

            <div className="photo col s12 m6">
              <img src="images/REFERENCE_6.jpg" alt="Amarikko reference image"/>
            </div>

            <div className="photo col s12 m6">
              <img src="images/REFERENCE_7.jpg" alt="Amarikko reference image"/>
            </div>

            <div className="photo col s12 m6">
              <img src="images/REFERENCE_8.jpg" alt="Amarikko reference image"/>
            </div>

            <div className="photo col s12 m6">
              <img src="images/REFERENCE_9.jpg" alt="Amarikko reference image"/>
            </div>

            <div className="photo col s12 m6">
              <img src="images/REFERENCE_10.jpg" alt="Amarikko reference image"/>
            </div>

            <div className="photo col s12 m6">
              <img src="images/REFERENCE_11.jpg" alt="Amarikko reference image"/>
            </div>

            <div className="photo col s12 m6">
              <img src="images/REFERENCE_12.jpg" alt="Amarikko reference image"/>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default App;
